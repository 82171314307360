<template>
  <v-container v-if="hasAccessFull">
    <v-alert type="info" v-if="saving">Creating Custom Item&hellip;</v-alert>

    <v-alert type="success" v-if="saved"
      >Created Custom Item #{{ message }}</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-card>
      <v-card-title>Custom Item</v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="2">
            <v-select
              v-model="newCustomItem.type"
              :items="customItemTypes"
              outlined
              label="Type"
            />
          </v-col>

          <v-col>
            <v-text-field
              @change="toggleAmountPartnerField"
              v-model="newCustomItem.partner_id"
              outlined
              label="Partner ID"
              hint="Verplicht; tenzij 'Advertiser' aanwezig is."
              persistent-hint
            ></v-text-field>
          </v-col>

          <v-col>
            <v-select
              @change="toggleAmountOrgField"
              v-model="newCustomItem.advertiser"
              :items="advertiserCodes"
              outlined
              label="Advertiser"
              hint="Verplicht; tenzij 'Partner ID' aanwezig is."
              persistent-hint
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col :cols="2">
            <v-text-field
              v-model="newCustomItem.amount_partner"
              outlined
              label="Bedrag (partner)"
              :disabled="disableAmountPartner"
            ></v-text-field>
          </v-col>

          <v-col :cols="2">
            <v-text-field
              v-model="newCustomItem.amount_org"
              outlined
              label="Bedrag (org)"
              :disabled="disableAmountOrg"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="newCustomItem.description"
              outlined
              label="Beschrijving"
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="newCustomItem.invoice_date"
              outlined
              label="Invoice Date (optioneel)"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-btn
          block
          x-large
          color="primary"
          :loading="saving"
          :disabled="saving"
          @click="createCustomItem()"
          >Custom Item Aanmaken</v-btn
        >
      </v-card-text>
    </v-card>
  </v-container>

  <v-container v-else>
    <v-alert type="warning">Met uw account kunt u deze gegevens wel bekijken maar niet aanpassen.</v-alert>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    errored: false,
    message: null,
    saving: false,
    saved: false,
    newCustomItem: {
      partner_id: null,
      advertiser: null,
      amount_org: 0,
      amount_partner: 0,
      description: null,
      type: null,
      invoice_date: null,
    },
    // Filters
    advertiserCodes: [],
    customItemTypes: [],
    // Toggles
    disableAmountPartner: true,
    disableAmountOrg: true,
  }),
  created() {
    this.fetchAdvertiserCodes();
    this.fetchCustomItemTypes();
  },
  methods: {
    createCustomItem() {
      this.saving = true;
      this.axios
        .post(`/custom_item/`, this.newCustomItem)
        .then((r) => {
          this.saved = true;
          this.errored = false;
          this.message = r.data.id;
        })
        .catch((e) => {
          this.saved = false;
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.saving = false));
    },
    // Fetch API Filters
    fetchAdvertiserCodes() {
      this.loading = true;
      this.axios
        .get(`/sales_short_project_code/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.advertiserCodes = r.data.data.map(({ name, value }) => ({
            value: name,
            text: value,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchCustomItemTypes() {
      this.loading = true;
      this.axios
        .get(`/custom_item_type/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.customItemTypes = r.data.data.map((value) => ({
            value,
            text: value,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    toggleAmountPartnerField() {
      const { partner_id } = this.newCustomItem;

      if (partner_id == null || partner_id.length === 0) {
        this.disableAmountPartner = true;
        this.newCustomItem.amount_partner = 0; // Reset
      } else {
        this.disableAmountPartner = false;
      }
    },
    toggleAmountOrgField() {
      const { advertiser } = this.newCustomItem;

      if (advertiser == null || advertiser.length === 0) {
        this.disableAmountOrg = true;
        this.newCustomItem.amount_org = 0; // Reset
      } else {
        this.disableAmountOrg = false;
      }
    },
  },
};
</script>
