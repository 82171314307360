<template>
  <v-container>
    <v-alert type="info" v-if="loading"
      >Retrieving Invoice Row #{{ $route.params.invoiceRowId }}&hellip;</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <!-- Invoice Row -->
    <v-row>
      <v-col>{{ invoiceRow }}</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    invoiceRow: null,
  }),
  created() {
    this.fetchInvoiceRow();
  },
  watch: {
    $route: "fetchInvoiceRow",
  },
  methods: {
    fetchInvoiceRow() {
      this.loading = true;
      this.axios
        .get(`/invoice_row/${this.$route.params.invoiceRowId}`)
        .then((r) => (this.invoiceRow = r.data.data))
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
