<template>
  <v-container>
    <v-row>
      <v-col v-for="({ title, subtitle, to }, i) in firstRowItems" :key="i">
        <v-card :to="to">
          <v-card-title>{{ title }}</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="({ title, subtitle, to }, i) in secondRowItems" :key="i">
        <v-card :to="to">
          <v-card-title>{{ title }}</v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    firstRowItems: [
      { title: "Imports", subtitle: "(Uitlijstingen)", to: "/imports" },
      { title: "Import Tasks", subtitle: "...", to: "/import-tasks" },
      { title: "Sales & Corrections", subtitle: "...", to: "/sales" },
    ],
    secondRowItems: [
      { title: "Invoice Rows", subtitle: "...", to: "/invoice-rows" },
      { title: "Export Runs", subtitle: "...", to: "/export-runs" },
      { title: "FTP Downloads", subtitle: "...", to: "/ftp-downloads" },
      { title: "Custom Items", subtitle: "...", to: "/custom-items" },
    ],
  }),
};
</script>
