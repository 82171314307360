<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving FTP Filters</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>
    <v-data-table :headers="headers" :items="ftpFilters" :items-per-page="15">
      <template v-slot:[`item.name`]="{ item }">
        <router-link
          :to="{ name: 'ftpDownloads.list', params: { ftpFilterId: item.id } }"
          >{{ item.name }}</router-link
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    ftpFilters: [],
    errored: false,
    message: null,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Name", value: "name" },
    ],
    loading: false,
  }),
  created() {
    this.fetchFTPFilters();
  },
  methods: {
    fetchFTPFilters() {
      console.log("here");
      this.loading = true;
      this.axios
        .get(`/ftp_filter/`)
        .then((r) => (this.ftpFilters = r.data.data))
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>