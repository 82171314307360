<template>
  <v-app v-if="keycloak">
    <v-app-bar app>
      <v-toolbar-title>
        <v-icon left color="primary" large>mdi-monitor-dashboard</v-icon>
        <strong>Invoicing</strong> Dashboard
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-chip label color="success" v-if="hasInvoicerAccessFull">
        <v-icon small left>mdi-lock-open-variant</v-icon>
        Full Access
      </v-chip>

      <v-chip label color="warning" v-if="hasInvoicerAccessRestricted">
        <v-icon small left>mdi-lock-open</v-icon>
        Restricted Access
      </v-chip>

      <v-chip label color="error" v-if="!hasInvoicerAccess">
        <v-icon small left>mdi-lock</v-icon>
        No Access
      </v-chip>

      <v-btn text @click="keycloak.logout()">
        <v-icon color="primary">mdi-logout</v-icon>
        Uitloggen
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer app v-if="hasInvoicerAccess">
      <v-list nav>
        <v-list-item
          v-for="({ title, to }, i) in items"
          :key="i"
          :to="to"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main v-if="hasInvoicerAccess">
      <router-view
        v-bind:has-access="hasInvoicerAccess"
        v-bind:has-access-full="hasInvoicerAccessFull"
        v-bind:has-access-restricted="hasInvoicerAccessRestricted"
      />
    </v-main>

    <v-main v-else>
      <v-container>
        <v-alert type="error">U heeft geen toegang tot deze applicatie</v-alert>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: ["keycloak"],
  data: () => ({
    items: [
      { title: "Home", to: "/" },
      { title: "Imports (Uitlijstingen)", to: "/imports" },
      { title: "Import Tasks", to: "/import-tasks" },
      { title: "Sales & Corrections", to: "/sales" },
      { title: "Invoice Rows", to: "/invoice-rows" },
      { title: "Export Runs", to: "/export-runs" },
      { title: "FTP Downloads", to: "/ftp-downloads" },
      { title: "Custom Items", to: "/custom-items" },
    ],
  }),
  computed: {
    hasInvoicerAccessFull: function () {
      return (
        this.keycloak &&
        this.keycloak.hasResourceRole("invoicer_full", "invoicer")
      );
    },
    hasInvoicerAccessRestricted: function () {
      return (
        this.keycloak &&
        this.keycloak.hasResourceRole("invoicer_restricted", "invoicer")
      );
    },
    hasInvoicerAccess: function () {
      return (
        this.keycloak &&
        (this.hasInvoicerAccessFull || this.hasInvoicerAccessRestricted)
      );
    },
  },
};
</script>
