<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving Export Runs&hellip;</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-data-table
      :headers="headers"
      :items="exportRuns"
      :items-per-page="15"
      :options.sync="options"
      :server-items-length="totalExportRuns"
      :loading="loading"
    >
      <template v-slot:[`item.description`]="{ item }">
        <router-link
          :to="{ name: 'exportRun.show', params: { exportRunId: item.id } }"
          >{{ item.description }}</router-link
        >
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          block
          rounded
          color="primary"
          :loading="downloading"
          :disabled="downloading || item.state !== 'finished'"
          @click="downloadCsvExport(item)"
          >Export CSV</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    exportRuns: [],
    totalExportRuns: 0,
    downloading: false,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Date (Exported)", value: "created_at" },
      { text: "Description", value: "description" },
      { text: "", value: "action" },
    ],
    options: {},
  }),
  watch: {
    $route: "fetchExportRuns",
    options: {
      handler() {
        this.fetchExportRuns();
      },
      deep: true,
    },
  },
  methods: {
    fetchExportRuns() {
      const { page, itemsPerPage, sortBy } = this.options;

      this.loading = true;

      this.axios
        .get(`/export_run/`, {
          params: {
            page_num: page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: true,
          },
        })
        .then((r) => {
          this.exportRuns = r.data.data;
          this.totalExportRuns = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    downloadCsvExport(item) {
      this.downloading = true;
      this.axios
        .get(`/export_run/${item.id}.csv`, {
          responseType: "blob", // Important!
        })
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.id}.csv`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.downloading = false));
    },
  },
};
</script>
