<template>
  <v-container>
    <v-alert type="info" v-if="loading"
      >Retrieving Export Run #{{ $route.params.exportRunId }}&hellip;</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <!-- Export Run -->
    <v-row>
      <v-col :cols="8">
        <v-card>
          <v-card-title>{{
            exportRun.description || "Loading&hellip;"
          }}</v-card-title>
          <v-card-subtitle>{{
            exportRun.created_at || "Loading&hellip;"
          }}</v-card-subtitle>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Filters</v-list-item-title>
                <v-list-item-subtitle>
                  <code>{{ exportRun.filters }}</code>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-data-table
          :headers="headers"
          :items="exportRun.invoice_row_ids"
          :items-per-page="5"
          :loading="loading"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{ name: 'invoiceRow.show', params: { invoiceRowId: item } }"
              >{{ item }}</router-link
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    exportRun: {
      id: null,
      filters: {},
      description: null,
      invoice_row_ids: [],
      created_at: null,
    },
    headers: [{ text: "ID", value: "id", sortable: false }],
  }),
  created() {
    this.fetchExportRun();
  },
  watch: {
    $route: "fetchExportRun",
  },
  methods: {
    fetchExportRun() {
      this.loading = true;
      this.axios
        .get(`/export_run/${this.$route.params.exportRunId}`)
        .then((r) => (this.exportRun = r.data.data))
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
