<template>
  <v-container>
    <v-alert type="info" v-if="loading"
      >Retrieving Import #{{ $route.params.importId }}&hellip;</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <!-- Import -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ singleImport.sale_reference || "—" }}</v-card-title>
          <v-card-subtitle>{{
            singleImport.external_sale_reference || "—"
          }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >External Confirmation ID</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.external_confirmation_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <!-- First Column -->
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Exported</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip
                        :color="getBooleanColor(singleImport.has_been_exported)"
                        small
                        text-color="white"
                        >{{
                          singleImport.has_been_exported ? "Yes" : "No"
                        }}</v-chip
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Import Created Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.created || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Status</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.status || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Manual Status</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.manual_status || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Final Status Task ID</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.status_final_task_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Import Row Number (ID)</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.import_rownumber || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- Second Column -->
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Advertiser Brand</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.advertiser_brand || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Date Bruto (Gross Date)</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.gross_date || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Import Status</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.import_status || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Manual Reason</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.manual_reason || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Customer Cancelled Reason</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.customer_cancelled_reason || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Import Task ID</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.import_task_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <!-- Third Column -->
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>External Customer ID</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.external_customer_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Final Status Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.status_final_datetime || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Import Substatus</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.import_substatus || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Manual Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      singleImport.manual_datetime || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Customer Cancelled Date</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.customer_cancelled_date || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Import Task Template Name</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      singleImport.import_task_template_name || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    singleImport: null,
  }),
  created() {
    this.fetchImport();
  },
  watch: {
    $route: "fetchImport",
  },
  methods: {
    fetchImport() {
      this.loading = true;
      this.axios
        .get(`/import_essent_sale_status/${this.$route.params.importId}`)
        .then((r) => (this.singleImport = r.data.data))
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    getBooleanColor(matched) {
      return matched ? "success" : "error";
    },
  },
};
</script>
