import Vue from "vue";
import Vuex from "vuex";

import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dateFrom: moment()
      .subtract(1, "month")
      .format("YYYY-MM-DD"),
    dateTo: null,
    access_token: 'empty'
  },
  mutations: {
    setFilterDates: (state, { dateFrom, dateTo }) => {
      // If-statements prevent the `Invalid date` errors.
      state.dateFrom = dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null;
      state.dateTo = dateTo ? moment(dateTo).format("YYYY-MM-DD") : null;
    },
    setAccessToken(state, token) {
      state.access_token = token;
    },
  },
  actions: {
    setAccessToken(context, token) {
      context.commit("setAccessToken", token);
    },
  }, 
  getters: {
    getAccessToken(state) {
      return state.access_token;
    },
  }
});
