import Vue from "vue";

// import * as Keycloak from "keycloak-js";
import Keycloak from "keycloak-js";
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import store from "@/store";

import "@/plugins/axios";

import App from "@/App";

Vue.config.productionTip = false;

let config = {
  url: process.env.VUE_APP_KEYCLOACK_URL || "https://35.157.183.54:8443/auth",
  realm: process.env.VUE_APP_KEYCLOACK_REALM || "Demo",
  clientId: process.env.VUE_APP_KEYCLOACK_CLIENT_ID || "invoicer",
  // onLoad: "login-required",
};

const initOptions = {
  onLoad: "login-required",
}

let keycloak = new Keycloak(config);

let app = new Vue({
  vuetify,
  router,
  store,
  render: (h) =>
    h(App, {
      props: {
        keycloak,
      },
    }),
});

keycloak
  .init(initOptions)
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      store.dispatch("setAccessToken", keycloak.token);
      console.info("Authenticated [SUCCESS]");
      app.$mount("#app");
    }

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            store.dispatch("setAccessToken", keycloak.token);
            console.info("Token Refreshed [SUCCESS]");
          } else {
            console.warn(
              "Token Refreshed [PENDING] - Valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch((e) => {
          console.error(e || "Token Refreshed [FAILED]");
        });
    }, 6000);
  })
  .catch((e) => console.error(e || "Authenticated [FAILED]"));
