<template>
  <v-container v-if="hasAccessFull">
    <v-alert type="info" v-if="loading">Loading&hellip;</v-alert>

    <v-alert type="success" v-if="saved">{{ message }}</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-file-input
          v-model="importFile"
          accept="text/csv"
          show-size
          counter
          outlined
          label="Import File"
        ></v-file-input>
      </v-col>

      <v-col>
        <v-select
          v-model="taskTemplateName"
          :items="importTaskTemplates"
          outlined
          label="Task Template"
        />
      </v-col>

      <v-col>
        <v-btn
          x-large
          block
          color="primary"
          :disabled="saved"
          @click="saveImportTask()"
          >Import Klaarzetten</v-btn
        >
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-alert type="warning">Met uw account kunt u deze gegevens wel bekijken maar niet aanpassen.</v-alert>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    saving: false,
    saved: false,
    importFile: null,
    taskTemplateName: null,
    importTaskTemplates: [],
  }),
  created() {
    this.fetchImportTaskTemplates();
  },
  watch: {
    $route: "",
  },
  methods: {
    saveImportTask() {
      const form = new FormData();

      form.append("import_file", this.importFile);
      form.append("template_name", this.taskTemplateName);

      this.axios
        .post(`/import_task_upload/`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((r) => {
          const { id, task_template_name } = r.data.data;

          this.saved = true;
          this.message = `Created Import Task #${id} with template '${task_template_name}'`;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchImportTaskTemplates() {
      this.loading = true;
      this.axios
        .get(`/import_task_template/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.importTaskTemplates = r.data.data.map(({ name }) => ({
            value: name,
            text: name,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
