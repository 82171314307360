<template>
  <v-container>
    <v-alert type="info" v-if="loading">Loading&hellip;</v-alert>

    <v-alert type="success" v-if="saved">
      Started Import Task ({{ message }})
    </v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-row>
          <v-col :cols="8"></v-col>
          <v-col>
            <router-link
              class="router-button"
              :to="{ name: 'importTask.create' }"
              v-if="hasAccessFull"
            >
              <v-btn x-large block color="success">Import Task Aanmaken</v-btn>
            </router-link>

            <v-btn x-large block color="success" disabled v-else
              >Import Task Aanmaken</v-btn
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="importTasks"
      :items-per-page="15"
      :options.sync="options"
      :server-items-length="totalImportTasks"
      :loading="loading"
    >
      <template v-slot:[`item.state`]="{ item }">
        <v-chip
          :color="getStateColor(item)"
          label
          text-color="white"
          style="text-transform: capitalize"
          >{{ item.state }}</v-chip
        >
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          block
          rounded
          color="primary"
          :loading="saving || item.state === 'running'"
          :disabled="
            saving || item.state === 'running' || item.task_finished !== null
          "
          @click="startImportTask(item)"
          v-if="hasAccessFull"
          >Start Import</v-btn
        >
        <v-btn
          block
          rounded
          color="primary"
          :loading="saving || item.state === 'running'"
          disabled
          v-else
          >Start Import</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import * as WorkerStateType from "../../misc/WorkerStateType";

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    importTasks: [],
    totalImportTasks: 0,
    saving: false,
    saved: false,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Status", value: "state" },
      { text: "Datum", value: "task_started" },
      { text: "Taak template naam", value: "task_template_name" },
      { text: "Nieuw toegevoegd", value: "rows_added" },
      { text: "Bestaand gewijzigd", value: "rows_modified" },
      { text: "Bestaand overgeslagen", value: "rows_skipped" },
      { text: "Error", value: "rows_errored" },
      { text: "", value: "action" },
    ],
    options: {},
  }),
  created() {},
  watch: {
    $route: "fetchImportTasks",
    options: {
      handler() {
        this.fetchImportTasks();
      },
      deep: true,
    },
  },
  methods: {
    fetchImportTasks() {
      const { page, itemsPerPage, sortBy } = this.options;

      this.loading = true;

      this.axios
        .get(`/import_task/`, {
          params: {
            page_num: page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: true,
          },
        })
        .then((r) => {
          this.importTasks = r.data.data;
          this.totalImportTasks = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    startImportTask(item) {
      const index = this.importTasks.map((i) => i.id).indexOf(item.id);

      this.saving = true;
      this.axios
        .post(`/import_task/${item.id}`, {
          start: true,
        })
        .then((r) => {
          this.saved = true;

          this.message = item.id;

          this.$set(this.importTasks, index, r.data.data);
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.saving = false));
    },
    getStateColor({ state }) {
      if (state === WorkerStateType.RUNNING) {
        return "blue";
      }

      if (state === WorkerStateType.FINISHED) {
        return "green";
      }

      if (state === WorkerStateType.FAILURE) {
        return "red";
      }

      return "";
    },
  },
};
</script>

<style scoped>
.router-button {
  text-decoration: none;
}
</style>
