import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";

import ImportOverview from "@/views/Imports/ImportOverview";
import ImportDetail from "@/views/Imports/ImportDetail";

import ImportTaskOverview from "@/views/ImportTasks/ImportTaskOverview";
import ImportTaskCreate from "@/views/ImportTasks/ImportTaskCreate";

import SaleOverview from "@/views/Sales/SaleOverview";
import SaleDetail from "@/views/Sales/SaleDetail";

import InvoiceRowOverview from "@/views/InvoiceRows/InvoiceRowOverview";
import InvoiceRowDetail from "@/views/InvoiceRows/InvoiceRowDetail";

import ExportRunOverview from "@/views/ExportRuns/ExportRunOverview";
import ExportRunDetail from "@/views/ExportRuns/ExportRunDetail";
import ExportRunCreate from "@/views/ExportRuns/ExportRunCreate";

import FTPDownloadOverview from "@/views/FtpDownloads/FTPDownloadOverview";
import FTPDownloadList from "@/views/FtpDownloads/FTPDownloadList";

import CustomItemOverview from "@/views/CustomItems/CustomItemOverview";
import CustomItemCreate from "@/views/CustomItems/CustomItemCreate";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  // Imports (Uitlijstingen)
  {
    path: "/imports",
    name: "import.index",
    component: ImportOverview,
  },
  {
    path: "/imports/:importId",
    name: "import.show",
    component: ImportDetail,
  },
  // Import Tasks
  {
    path: "/import-tasks",
    name: "importTask.index",
    component: ImportTaskOverview,
  },
  {
    path: "/import-tasks/create",
    name: "importTask.create",
    component: ImportTaskCreate,
  },
  // Sales & Corrections
  {
    path: "/sales",
    name: "sale.index",
    component: SaleOverview,
  },
  {
    path: "/sales/:saleId",
    name: "sale.show",
    component: SaleDetail,
  },
  // Invoice Rows
  {
    path: "/invoice-rows",
    name: "invoiceRow.index",
    component: InvoiceRowOverview,
  },
  {
    path: "/invoice-rows/:invoiceRowId",
    name: "invoiceRow.show",
    component: InvoiceRowDetail,
  },
  // Export Runs
  {
    path: "/export-runs",
    name: "exportRun.index",
    component: ExportRunOverview,
  },
  {
    path: "/export-runs/create",
    name: "exportRun.create",
    component: ExportRunCreate,
  },
  {
    path: "/export-runs/:exportRunId",
    name: "exportRun.show",
    component: ExportRunDetail,
  },
  // FTP Downloads
  {
    path: "/ftp-downloads",
    name: "ftpDownloads.index",
    component: FTPDownloadOverview,
  },
  {
    path: "/ftp-downloads/:ftpFilterId",
    name: "ftpDownloads.list",
    component: FTPDownloadList,
  },
  {
    path: "/ftp-file/:ftpFilterId/:ftpFileName",
    name: "ftpDownloads.file",
    component: FTPDownloadList,
  },
  // Custom Items
  {
    path: "/custom-items",
    name: "customItem.index",
    component: CustomItemOverview,
  },
  {
    path: "/custom-items/create",
    name: "customItem.create",
    component: CustomItemCreate,
  },
];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
