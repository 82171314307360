<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving Imports&hellip;</v-alert>

    <v-alert type="success" v-if="saved"
      >Saved Manual Status ({{ message }})</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.externalSaleReference"
          outlined
          label="External Sale Reference"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filters.externalConfirmationId"
          outlined
          label="External Confirmation ID"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-switch
          v-model="filters.showManualSet"
          label="Show Manual Set"
        ></v-switch>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filters.dateFrom"
          outlined
          label="From YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filters.dateTo"
          outlined
          label="To YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn
          x-large
          block
          color="primary"
          @click="fetchImports({ resetPagination: true })"
          >Filters Toepassen</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="
        filters.showManualSet
          ? imports
          : imports.filter((item) => item.manual_status === null)
      "
      :items-per-page="15"
      :options.sync="options"
      :server-items-length="totalImports"
      :loading="loading"
    >
      <template v-slot:[`item.external_sale_reference_trimmed`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <router-link
                :to="{ name: 'import.show', params: { importId: item.id } }"
                >{{
                  item.sale_reference ||
                  `External Sale Reference: ${item.external_sale_reference_trimmed}`
                }}</router-link
              >
            </span>
          </template>
          <span>{{ item.external_sale_reference }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.sale_status`]="{ item }">
        <v-select
          v-model="item.sale.status"
          :items="statesTransitionableEnum"
          :disabled="item.import_action !== 'Manual' || !item.matched"
        />
      </template>

      <template v-slot:[`item.sale_manual_reason`]="{ item }">
        <v-text-field
          v-model="item.sale.manual_reason"
          :disabled="item.import_action !== 'Manual' || !item.matched"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          block
          rounded
          color="primary"
          :loading="saving"
          :disabled="
            saving ||
            item.import_action !== 'Manual' ||
            !item.can_be_modified ||
            !item.matched
          "
          @click="saveManualChange(item.sale)"
          v-if="hasAccessFull"
          >Opslaan</v-btn
        >

        <v-btn block rounded color="primary" :loading="saving" disabled v-else
          >Opslaan</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import * as SaleStatus from "../../misc/SaleStatus";

const writeFilterAttr = ({
  dateFrom,
  dateTo,
  externalSaleReference,
  externalConfirmationId,
}) =>
  [
    { field: "import_action", operator: "$eq", value: SaleStatus.MANUAL },
    externalSaleReference && {
      field: "external_sale_reference",
      operator: "$contains",
      value: externalSaleReference,
    },
    externalConfirmationId && {
      field: "external_confirmation_id",
      operator: "$contains",
      value: externalConfirmationId,
    },
    dateFrom && {
      field: "gross_date",
      operator: "$ge",
      value: moment(dateFrom).format("YYYY-MM-DD"),
    },
    dateTo && {
      field: "gross_date",
      operator: "$lt",
      value: moment(dateTo).add(1, "day").format("YYYY-MM-DD"),
    },
  ].filter((v) => v);

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    imports: [],
    totalImports: 0,
    saving: false,
    saved: false,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Date Bruto (Gross Date)", value: "gross_date" },
      { text: "Partner ID", value: "partner_id" },
      {
        text: "Sale Reference",
        value: "external_sale_reference_trimmed",
        // I don't like this ~ Stephan
      },
      { text: "Import Action (previously 'Status')", value: "import_action" },
      { text: "Import Status", value: "import_status" },
      { text: "Import Substatus", value: "import_substatus" },
      { text: "Handmatige (Sale) status", value: "sale_status" },
      { text: "Handmatige reden", value: "sale_manual_reason" },
      { text: "Handmatig aangepast op", value: "sale_manual_datetime" },
      { text: "", value: "action" },
    ],
    options: {},
    statesTransitionableEnum: [
      { value: SaleStatus.BRUTO, text: "Bruto", disabled: true },
      { value: SaleStatus.NETTO, text: "Netto", disabled: false },
      { value: SaleStatus.CANCELLED, text: "Geannuleerd", disabled: false },
      { value: SaleStatus.NOSALE, text: "Geen Sale", disabled: false },
    ],
    filters: {
      showManualSet: true,
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: null,
      externalSaleReference: null,
      externalConfirmationId: null,
    },
  }),
  created() {
    this.getGlobalFilterDates();
  },
  watch: {
    $route: "fetchImports",
    options: {
      handler() {
        this.fetchImports({ resetPagination: false });
      },
      deep: true,
    },
  },
  methods: {
    fetchImports({ resetPagination }) {
      this.setGlobalFilterDates();

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loading = true;
      this.axios
        .get(`/import_essent_sale_status/`, {
          params: {
            page_num: resetPagination ? 1 : page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: sortDesc[0],
            filter: writeFilterAttr(this.filters),
          },
        })
        .then((r) => {
          this.imports = r.data.data;
          this.totalImports = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.loading = false));
    },
    saveManualChange({ id, status, manual_reason }) {
      const index = this.imports.map((i) => i.id).indexOf(id);

      this.saving = true;
      this.axios
        .post(`/sale/${id}/status/`, {
          status,
          manual_reason,
        })
        .then((r) => {
          const {
            sale_reference,
            external_sale_reference_trimmed,
          } = r.data.data;

          this.saved = true;
          this.message = sale_reference || external_sale_reference_trimmed;

          this.$set(this.imports, index, r.data.data);
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.saving = false));
    },
    getGlobalFilterDates() {
      const { dateFrom, dateTo } = this.$store.state;

      this.filters.dateFrom = dateFrom;
      this.filters.dateTo = dateTo;
    },
    setGlobalFilterDates() {
      let { dateFrom, dateTo } = this.filters;

      if (dateFrom === "") {
        dateFrom = null;
      }

      if (dateTo === "") {
        dateTo = null;
      }

      this.$store.commit("setFilterDates", { dateFrom, dateTo });
    },
  },
};
</script>
