export const INDETERMINATE = 'indeterminate';
export const UNEXPORTED = 'unexported';
export const EXPORTED = 'exported';

export const filter = (model, exportStatus) => {
  return (exportStatus !== INDETERMINATE) && {
    field: "has_been_exported",
    operator: "$eq",
    value: (exportStatus === EXPORTED),
    class: model
  };
};
