<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving Files</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>
    <v-data-table :headers="headers" :items="files" :items-per-page="15">
      <template v-slot:[`item.name`]="{ item }">
        <a
          :href="url_"
          v-text="item.file"
          @click.prevent="downloadFile(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    files: [],
    errored: false,
    message: null,
    url_: "http://localhost:8080/",
    downloading: false,
    headers: [
      { text: "File", value: "name" },
      { text: "Modified", value: "date" },
    ],
    loading: false,
  }),
  created() {
    this.fetchFiles();
  },
  methods: {
    fetchFiles() {
      this.loading = true;
      this.axios
        .get(`/ftp_list_files/${this.$route.params.ftpFilterId}`)
        .then((r) => (this.files = r.data.data))
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    downloadFile(item) {
      console.log(item);
      this.axios
        .get(`/ftp_file/${item.filter}/${item.file}`, {
          responseType: "blob", // Important!
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]), {
            type: "application/octet-stream",
          });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${item.file}`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.downloading = false));
    },
  },
};
</script>