<template>
  <v-container>
    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-alert type="success" v-if="saved">{{ message }}</v-alert>

    <v-alert type="info" v-if="loading"
      >Retrieving Sale #{{ $route.params.saleId }}&hellip;</v-alert
    >

    <!-- Sale -->
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ sale.sale_reference || "—" }}</v-card-title>
          <v-card-subtitle>{{
            sale.external_sale_reference || "—"
          }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col :cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >External Confirmation ID</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      sale.external_confirmation_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col v-if="hasAccessFull">
                <v-row>
                  <v-col :cols="3">
                    <v-select
                      outlined
                      label="(Final) Status"
                      v-model="sale.status"
                      :items="statesTransitionableEnum"
                      :disabled="
                        sale.status === 'Manual' || sale.has_been_exported
                      "
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      outlined
                      label="Manual Reason"
                      v-model="sale.manual_reason"
                      :disabled="
                        sale.status === 'Manual' || sale.has_been_exported
                      "
                    />
                  </v-col>
                  <v-col :cols="3">
                    <v-btn
                      block
                      x-large
                      color="primary"
                      :loading="saving"
                      :disabled="
                        saving ||
                        sale.status === 'Manual' ||
                        sale.has_been_exported
                      "
                      @click="updateSaleStatus($route.params.saleId)"
                      >Opslaan</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Final Status</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ sale.status || "(not available)" }}
                      <v-chip
                        :color="getFinalStatusColor(sale)"
                        x-small
                        text-color="white"
                        >{{ getFinalStatusText(sale) }}</v-chip
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      >Sale &amp; Import Match</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <v-chip
                        :color="getBooleanColor(sale.matched)"
                        small
                        text-color="white"
                        >{{ sale.matched ? "Yes" : "No" }}</v-chip
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Exported</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-menu
                        bottom
                        right
                        transition="scale-transition"
                        origin="top left"
                      >
                        <template v-slot:activator="{ on }">
                          <v-chip
                            :color="getBooleanColor(sale.has_been_exported)"
                            small
                            text-color="white"
                            @click="fetchExportedInvoiceRows()"
                            v-on="sale.has_been_exported ? on : {}"
                            >{{ sale.has_been_exported ? "Yes" : "No" }}</v-chip
                          >
                        </template>
                        <v-card width="450">
                          <v-list dark>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Aangemaakte facturen</v-list-item-title
                                >
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn icon @click="menu = false">
                                  <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                          <template>
                            <v-data-table
                              :headers="headers_eir"
                              :items="exported_invoice_rows"
                              :items-per-page="5"
                              class="elevation-1"
                            ></v-data-table>
                          </template>
                        </v-card>
                      </v-menu>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title>Amount(s)</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ sale.fee_org }} (org)</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      >{{ sale.fee_partner }} (partner)</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Advertiser</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.short_project_code || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Project Code</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.project_code || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Partner ID</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.partner_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Networker ID</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.networker_id || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Sale Closed Date</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.sale_closed_date || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Quantity</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.quantity || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Unit</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.unit || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Frequency</v-list-item-title>
                    <v-list-item-subtitle>{{
                      sale.frequency || "—"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Corrections -->
    <v-card>
      <v-card-title>Corrections</v-card-title>
      <v-card-text v-if="hasAccessFull">
        <v-row>
          <v-col>
            <v-select
              @change="changedType"
              v-model="newCorrection.correction_type"
              :items="correctionTypes"
              outlined
              label="Type"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="newCorrection.amount_org"
              outlined
              label="Bedrag (org)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="newCorrection.amount_partner"
              outlined
              label="Bedrag (partner)"
            ></v-text-field>
          </v-col>
          <v-col :cols="4">
            <v-text-field
              v-model="newCorrection.description"
              outlined
              label="Beschrijving"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="newCorrection.date"
              outlined
              label="Datum (optioneel)"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              block
              x-large
              color="primary"
              :loading="saving"
              :disabled="saving"
              @click="addCorrectionOrComplaint()"
              >Toevoegen</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else>
        <v-alert type="warning">Met uw account kunt u deze gegevens wel bekijken maar niet aanpassen.</v-alert>
      </v-card-text>
    </v-card>

    <v-alert type="info" v-if="loadingCorrections">Retrieving Corrections&hellip;</v-alert>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="corrections"
          :items-per-page="15"
          :options.sync="options"
          :server-items-length="totalCorrections"
          :loading="loadingCorrections"
        >
          <template v-slot:[`item.has_been_exported`]="{ item }">
            <v-simple-checkbox
              v-model="item.has_been_exported"
              disabled
            ></v-simple-checkbox>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              block
              small
              outlined
              color="error"
              :disabled="!item.can_be_modified || hasAccessRestricted"
              @click="removeCorrection(item)"
              >Verwijderen</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as CorrectionType from "../../misc/CorrectionType";
import * as SaleStatus from "../../misc/SaleStatus";

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    saving: false,
    saved: false,
    loading: false,
    loadingCorrections: false,
    errored: false,
    message: null,
    sale: {
      sale_reference: null,
      external_sale_reference: null,
      final_status: null,
      status: null,
      manual_reason: null,
      matched: false,
      manual: false,
      fee_org: 0,
      fee_partner: 0,
      project_code: null,
      short_project_code: null,
      partner_id: null,
      networker_id: null,
      sale_closed_date: null,
      quantity: 0,
      unit: null,
      frequency: null,
    },
    corrections: [],
    exported_invoice_rows: [],
    totalCorrections: 0,
    newCorrection: {
      complaint_id: null,
      amount_org: null,
      amount_partner: null,
      description: "Verwerking annulering",
      date: null,
      correction_type: CorrectionType.CANCELLED,
    },
    correctionType: CorrectionType.CANCELLED,
    correctionTypes: [
      { text: "Correctie", value: CorrectionType.CORRECTION },
      { text: "Annulering", value: CorrectionType.CANCELLED },
      { text: "Bonus", value: CorrectionType.BONUS },
      { text: "Klacht", value: CorrectionType.COMPLAINT },
    ],
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Datum", value: "date" },
      { text: "Bedrag (org)", value: "amount_org" },
      { text: "Bedrag (partner)", value: "amount_partner" },
      { text: "Omschrijving", value: "description" },
      { text: "Exported", value: "has_been_exported" },
      { text: "Type", value: "correction_type" },
      { text: "", value: "action", sortable: false },
    ],
    headers_eir: [
      { text: "ID", value: "id", sortable: false },
      { text: "type", value: "invoice_category", sortable: false },
      { text: "bedrag", value: "amount", sortable: false },
      { text: "export datum", value: "export_date" },
    ],
    options: {},
    statesTransitionableEnum: [
      { value: SaleStatus.BRUTO, text: "Bruto", disabled: false },
      { value: SaleStatus.NETTO, text: "Netto", disabled: false },
      { value: SaleStatus.CANCELLED, text: "Geannuleerd", disabled: false },
      { value: SaleStatus.NOSALE, text: "Geen Sale", disabled: false },
    ],
  }),
  created() {
    this.fetchOneSale();
  },
  watch: {
    $route: ["fetchOneSale", "fetchSaleCorrections"],
    options: {
      handler() {
        this.exported_invoice_rows = [];
        this.fetchSaleCorrections();
      },
      deep: true,
    },
  },
  methods: {
    fetchOneSale() {
      this.loading = true;
      this.axios
        .get(`/sale/${this.$route.params.saleId}`)
        .then((r) => {
          const { fee_org, fee_partner } = r.data.data;

          this.sale = r.data.data;
          this.newCorrection.amount_org = -fee_org;
          this.newCorrection.amount_partner = -fee_partner;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchSaleCorrections() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loadingCorrections = true;
      this.axios
        .get(`/sale/${this.$route.params.saleId}/correction/`, {
          params: {
            page_num: page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: sortDesc[0],
          },
        })
        .then((r) => {
          this.corrections = r.data.data;
          this.totalCorrections = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.loadingCorrections = false));
    },
    fetchExportedInvoiceRows() {
      this.axios
        .get(`/sale/${this.$route.params.saleId}/exported_invoice_rows/`)
        .then((r) => {
          this.exported_invoice_rows = r.data.data;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        });
    },
    addCorrectionOrComplaint() {
      if (this.correctionType == CorrectionType.COMPLAINT) {
        this.addComplaint();
      } else {
        this.addCorrection();
      }
    },
    addCorrection() {
      this.saving = true;
      this.axios
        .post(
          `/sale/${this.$route.params.saleId}/correction/`,
          this.newCorrection
        )
        .then(() => {
          // Vuetify v-data-table isn’t working well with newly pushed onto the stack items,
          // therefore this workaround solution is chosen -- retrieving all (new) Corrections.
          this.fetchSaleCorrections();
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.saving = false));
    },
    removeCorrection(item) {
      const index = this.corrections.map((i) => i.id).indexOf(item.id);

      this.saving = true;
      this.axios
        .delete(`/sale/${this.$route.params.saleId}/correction/${item.id}`)
        .then(() => {
          this.corrections.splice(index, 1);
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.saving = false));
    },
    addComplaint() {
      this.saving = true;
      this.axios
        .post(`/complaint/`, this.newCorrection)
        .then((r) => {
          const { id } = r.data.data;
          this.newCorrection.complaint_id = id;
          this.addCorrection(); // Create new Correction with `complaint_id`
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.saving = false));
    },
    updateSaleStatus(saleId) {
      const { status, manual_reason } = this.sale;

      this.saving = true;
      this.axios
        .post(`/sale/${saleId}/status/`, {
          status,
          manual_reason,
        })
        .then((r) => {
          const { id, status } = r.data.data;

          this.saved = true;
          this.message = `Saved Sale #${id} with (final) status '${status}'`;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response ? e.response.data.message : e.toString();
        })
        .then(() => (this.saving = false));
    },
    getBooleanColor(matched) {
      return matched ? "success" : "error";
    },
    getFinalStatusColor({ final_status, status, manual }) {
      if (final_status !== null || status !== null) {
        return manual ? "blue" : "purple";
      }

      return "";
    },
    getFinalStatusText({ final_status, status, manual }) {
      if (final_status !== null || status !== null) {
        return manual
          ? "This status has been manually specified."
          : "This status has been automatically matched.";
      }

      return "N/A";
    },
    changedType(event) {
      this.newCorrection.description =
        "Verwerking " +
        this.correctionTypes.find((el) => el.value == event).text.toLowerCase();

      if (event === "cancelled") {
        this.newCorrection.amount_org = -this.sale.fee_org;
        this.newCorrection.amount_partner = -this.sale.fee_partner;
      } else if (event === "bonus") {
        this.newCorrection.amount_org = 0;
        this.newCorrection.amount_partner = this.sale.fee_partner * 0.1;
      } else if (event === "complaint") {
        this.newCorrection.amount_org = -this.sale.fee_partner;
        this.newCorrection.amount_partner = -this.sale.fee_partner;
      } else {
        this.newCorrection.amount_org = 0;
        this.newCorrection.amount_partner = 0;
      }
    },
  },
};
</script>
