<template>
  <v-container v-if="hasAccessFull">
    <v-alert type="info" v-if="saving">Creating Export Run&hellip;</v-alert>

    <v-alert type="success" v-if="saved">Created Export Run #{{ message }}</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <!-- Export Run -->
    <v-row>
      <v-col :cols="8">
        <v-text-field v-model="newExportRun.description" outlined label="Description"></v-text-field>
      </v-col>

      <v-col>
        <v-btn x-large block color="primary" :disabled="saved" @click="create()">Exporteren</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Filters</v-card-title>
          <v-card-subtitle>
            <code>{{ newExportRun.filters }}</code>
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>Blacklist</v-card-title>
          <v-data-table :headers="headers" :items="newExportRun.blacklist" :items-per-page="5">
            <template v-slot:[`item.id`]="{ item }">
              <router-link :to="{
                name: 'invoiceRow.show',
                params: { invoiceRowId: item },
              }">{{ item }}</router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else>
    <v-alert type="warning">Met uw account kunt u deze gegevens wel bekijken maar niet aanpassen.</v-alert>
  </v-container>
</template>

<script>
export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    errored: false,
    message: null,
    saving: false,
    saved: false,
    selectedItem: null,
    newExportRun: {
      filters: {},
      description: null,
      blacklist: [], // Keep empty (for now)!
    },
    headers: [{ text: "ID", value: "id", sortable: false }],
  }),
  created() {
    const { exportRunFilters, blacklist, selectedItem } = this.$route.params;
    this.selectedItem = selectedItem;
    if (selectedItem) {
      this.fetchTemplate(selectedItem)
    } else {
      this.newExportRun.filters = exportRunFilters;
      this.newExportRun.filters.push({
        field: "id",
        operator: "$nin",
        value: blacklist,
      });
    }

  },
  methods: {
    fetchTemplate(selectedItem) {
      this.loading = true;
      this.axios
        .get(`/export_runs_by_template/${selectedItem}`)
        .then((r) => {
          // Map into v-select understandable object.
          this.newExportRun.description = r.data.name;
          this.newExportRun.filters = r.data.filters;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    create() {
      if (this.selectedItem) {
        this.createExportRunByTemplate();
      } else {
        this.createExportRun();
      }
    },
    createExportRun() {
      this.saving = true;
      this.axios
        .post(`/export_run/`, this.newExportRun)
        .then((r) => {
          console.log(r.data);

          this.saved = true;
          this.message = r.data.id;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.saving = false));
    },
    createExportRunByTemplate() {
      this.saving = true;
      this.axios
        .post(`/export_runs_by_template/`, this.newExportRun)
        .then((r) => {

          this.saved = true;
          this.message = r.data.id;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.saving = false));
    },
  },
};
</script>
