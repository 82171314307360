<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving Invoice Rows&hellip;</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-select v-model="filters.exportStatus" :items="exportStatuses" label="Export Status" :disabled="!defaultMode" />
      </v-col>
      <v-col>
        <v-select v-model="filters.invoiceCategory" :items="invoiceCategories" label="Invoice Type" multiple
          :disabled="!defaultMode" />
      </v-col>
      <v-col>
        <v-select v-model="filters.finalStatus" :items="finalStatuses" label="Final Status" multiple
          :disabled="!defaultMode" />
      </v-col>
      <v-col>
        <v-select v-model="filters.shortProjectCode" :items="shortProjectCodes" label="Advertiser" multiple
          :disabled="!defaultMode" />
      </v-col>
      <v-col>
        <v-select v-model="filters.productType" :items="productTypes" label="Product Type" multiple
          :disabled="!defaultMode" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="filters.dateFrom" outlined label="From YYYY-MM-DD" :disabled="!defaultMode"></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="filters.dateTo" outlined label="To YYYY-MM-DD" :disabled="!defaultMode"></v-text-field>
      </v-col>
      <v-col>
        <v-select v-model="selectedItem" ref="selectedTemplate" class="selected-template" :items="exportRunTemplates"
          label="Template" />
      </v-col>
      <v-col>
        <v-btn x-large block color="primary" @click="fetchInvoiceRows({ resetPagination: true })">Filters
          Toepassen</v-btn>
      </v-col>
      <v-col>
        <router-link class="router-button" :to="{
          name: 'exportRun.create',
          params: { exportRunFilters, blacklist, selectedItem },
        }" v-if="hasAccessFull">
          <v-btn x-large block color="success">Export Aanmaken</v-btn>
        </router-link>

        <v-btn x-large block color="success" disabled v-else>Export Aanmaken</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Total Amount</v-list-item-title>
                <v-list-item-subtitle>{{ totalAmount }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div>{{ mode }}</div>
    <v-data-table ref="table" @item-selected="itemSelect" @toggle-select-all="toggleSelectAll" :headers="headers"
      :items="invoiceRows" :items-per-page="15" :options.sync="options" :server-items-length="totalInvoiceRows"
      show-select :loading="loading">
      <template v-slot:[`item.sale_reference`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <router-link :to="{
                name: 'invoiceRow.show',
                params: { invoiceRowId: item.id },
              }">{{ item.sale_reference }}</router-link>
            </span>
          </template>
          <span>{{ item.external_sale_reference }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.has_been_exported`]="{ item }">
        <v-simple-checkbox v-model="item.has_been_exported" disabled></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import * as ExportStatus from "../../misc/ExportStatus.js";

const writeFilterAttr = ({
  dateFrom,
  dateTo,
  invoiceCategory,
  finalStatus,
  shortProjectCode,
  productType,
  exportStatus,

}) =>
  [
    ExportStatus.filter("InvoiceRowModel", exportStatus),
    invoiceCategory.length > 0 && {
      field: "invoice_category",
      operator: "$in",
      value: invoiceCategory,
    },
    finalStatus.length > 0 && {
      field: "final_status",
      operator: "$in",
      value: finalStatus,
    },
    shortProjectCode.length > 0 && {
      field: "short_project_code",
      operator: "$in",
      value: shortProjectCode,
    },
    productType.length > 0 && {
      field: "product_type",
      operator: "$in",
      value: productType,
    },
    dateFrom && {
      field: "date_applicable",
      operator: "$ge",
      value: moment(dateFrom).format("YYYY-MM-DD"),
    },
    dateTo && {
      field: "date_applicable",
      operator: "$lt",
      value: moment(dateTo).add(1, "day").format("YYYY-MM-DD"),
    },
  ].filter((v) => v);

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    mode: 'default',
    invoiceRows: [],
    totalInvoiceRows: 0,
    totalAmount: 0,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Date (Sale Closed / Correction)", value: "date_applicable" },
      { text: "Debtor", value: "debtor" },
      { text: "Sale Reference", value: "sale_reference" },
      { text: "Final Status", value: "final_status" },
      { text: "Amount", value: "amount" },
      { text: "Invoice Type", value: "invoice_category" },
      { text: "Exported", value: "has_been_exported" },
      { text: "Partner ID", value: "partner_id" },
      { text: "Partner Company Name", value: "company_name" },
    ],
    options: {},
    invoiceCategories: [
      { value: "networker", text: "Netwerker" },
      { value: "partner", text: "Partner" },
      { value: "org", text: "Adverteerder" },
      { value: "correction_networker", text: "Correcties networker" },
      { value: "correction_partner", text: "Correcties partner" },
      { value: "correction_org", text: "Correcties adverteerder" },
      { value: "custom_item_partner", text: "Custom Item partner" },
      { value: "custom_item_org", text: "Custom Item adverteerder" },
      { value: "employee", text: "Medewerker" },
      { value: "cherries_networker", text: "Cherries Netwerker" },
    ],
    exportStatuses: [
      { value: ExportStatus.EXPORTED, text: "Geëxporteerd" },
      { value: ExportStatus.UNEXPORTED, text: "Niet geëxporteerd" },
      { value: ExportStatus.INDETERMINATE, text: "Ongeacht geëxporteerd" },
    ],
    finalStatuses: [
      { value: "Bruto", text: "Bruto" },
      { value: "Netto", text: "Netto" },
      { value: "Cancelled", text: "Geannuleerd" },
      { value: "NoSale", text: "Geen Sale" },
    ],
    shortProjectCodes: [],
    productTypes: [],
    exportRunTemplates: [],
    selectedItem: null,
    filters: {
      exportStatus: ExportStatus.UNEXPORTED,
      invoiceCategory: [],
      finalStatus: [],
      shortProjectCode: [],
      productType: [],
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: null,
    },
    blacklist: [],
    exportRunFilters: [],
  }),
  created() {
    this.fetchSalesProductTypes();
    this.fetchSalesShortProjectCodes();
    this.fetchExportRunTemplates();
    this.getGlobalFilterDates();
  },
  watch: {
    $route: "fetchInvoiceRows",
    options: {
      handler() {
        this.fetchInvoiceRows({ resetPagination: false });
      },
      deep: true,
    },
    selectedItem(item) {
      if (!item || item === 'Geen') {

        this.mode = 'default'
        this.selectedItem = null
        this.$refs["selectedTemplate"].blur();

      } else {

        this.mode = 'template'

      }
    }
  },
  computed: {
    defaultMode: function () {
      return this.mode === 'default';
    }
  },
  methods: {
    itemSelect({ item, value }) {
      if (value) {
        this.blacklist = this.blacklist.filter((id) => id !== item.id); // Removal from blacklist
      } else if (!value && this.blacklist.indexOf(item.id) === -1) {
        this.blacklist.push(item.id); // Addition to blacklist
      }
    },
    toggleSelectAll({ items, value }) {
      items.forEach((item) => this.itemSelect({ item, value }));
    },
    setModeDefault() {
      this.mode = 'default'
      /* use this to use default mode, create filters etc and set apply filters to default URL */
    },
    setModeTemplate() {
      this.mode = 'template'
      /* use this to use teamplate mode, disable all other options and set apply filters to InvoiceTemplateURL*/
    },
    fetchInvoiceRows({ resetPagination }) {

      let route_path = `/invoice_row/`

      if (this.mode == 'default') {
        route_path = `/invoice_row/`
        this.setGlobalFilterDates();
      } else {
        route_path = `/temp_invoice_row/?template_id=${this.selectedItem}`
      }

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      if (resetPagination) {
        this.blacklist = [];
      }

      this.loading = true;
      this.axios
        .get(route_path, {
          params: {
            page_num: resetPagination ? 1 : page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: sortDesc[0],
            filter: (this.exportRunFilters = writeFilterAttr(this.filters)),
          },
        })
        .then((r) => {
          this.invoiceRows = r.data.data;
          this.totalInvoiceRows = r.data._meta.pagination.total;
          this.totalAmount = r.data._meta.sum.amount;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => {
          this.loading = false;
          this.$refs.table.items.forEach((ir) =>
            this.$refs.table.select(ir, this.blacklist.indexOf(ir.id) === -1)
          );
        });
    },
    fetchSalesProductTypes() {
      this.loading = true;
      this.axios
        .get(`/sales_product_type/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.productTypes = r.data.data.map((value) => ({
            value,
            text: value,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchSalesShortProjectCodes() {
      this.loading = true;
      this.axios
        .get(`/sales_short_project_code/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.shortProjectCodes = r.data.data.map(({ name, value }) => ({
            value: value,
            text: name,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchExportRunTemplates() {
      this.loading = true;
      this.axios
        .get(`/export_runs_by_template/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.exportRunTemplates = [{ 'text': 'Geen', 'id': -1 }, ...r.data.data.map(({ name, id }) => ({
            value: id,
            text: name,
          }))];
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    getGlobalFilterDates() {
      const { dateFrom, dateTo } = this.$store.state;

      this.filters.dateFrom = dateFrom;
      this.filters.dateTo = dateTo;
    },
    setGlobalFilterDates() {
      let { dateFrom, dateTo } = this.filters;

      if (dateFrom === "") {
        dateFrom = null;
      }

      if (dateTo === "") {
        dateTo = null;
      }

      this.$store.commit("setFilterDates", { dateFrom, dateTo });
    },
  },
};
</script>

<style scoped>
.router-button {
  text-decoration: none;
}

.selected-template {
  color: rgba(0, 0, 0, 0.6);
}
</style>
