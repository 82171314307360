<template>
  <v-container>
    <v-alert type="info" v-if="loading"
      >Retrieving Custom Items&hellip;</v-alert
    >

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.partnerId"
          outlined
          label="Partner ID"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-select
          v-model="filters.advertiser"
          :items="advertiserCodes"
          outlined
          label="Advertiser"
          multiple
        />
      </v-col>

      <v-col>
        <v-select
          v-model="filters.type"
          :items="customItemTypes"
          outlined
          label="Custom Item Type"
          multiple
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.dateFrom"
          outlined
          label="From YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="filters.dateTo"
          outlined
          label="To YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-btn
          x-large
          block
          color="primary"
          @click="fetchCustomItems({ resetPagination: true })"
          >Filters Toepassen</v-btn
        >
      </v-col>

      <v-col>
        <router-link
          class="router-button"
          :to="{ name: 'customItem.create' }"
          v-if="hasAccessFull"
        >
          <v-btn x-large block color="success">Item Toevoegen</v-btn>
        </router-link>

        <!-- Ugly if/else, but router-link cannot be disabled... -->
        <v-btn x-large block color="success" disabled v-else
          >Item Toevoegen</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="customItems"
      :items-per-page="15"
      :options.sync="options"
      :server-items-length="totalCustomItems"
      :loading="loading"
    >
      <template v-slot:[`item.has_been_exported`]="{ item }">
        <v-simple-checkbox
          v-model="item.has_been_exported"
          disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";

const writeFilterAttr = ({ partnerId, advertiser, type, dateFrom, dateTo }) =>
  [
    partnerId && {
      field: "partner_id",
      operator: "$contains",
      value: partnerId,
    },
    advertiser.length > 0 && {
      field: "advertiser",
      operator: "$in",
      value: advertiser,
    },
    type.length > 0 && {
      field: "type",
      operator: "$in",
      value: type,
    },
    dateFrom && {
      field: "invoice_date",
      operator: "$ge",
      value: moment(dateFrom).format("YYYY-MM-DD"),
    },
    dateTo && {
      field: "invoice_date",
      operator: "$lt",
      value: moment(dateTo).add(1, "day").format("YYYY-MM-DD"),
    },
  ].filter((v) => v);

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    customItems: [],
    totalCustomItems: 0,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Partner ID", value: "partner_id" },
      { text: "Advertiser", value: "advertiser" },
      { text: "Description", value: "description" },
      { text: "Type", value: "type" },
      { text: "Amount (partner)", value: "amount_partner" },
      { text: "Amount (org)", value: "amount_org" },
      { text: "Invoice Date", value: "invoice_date" },
      { text: "Exported", value: "has_been_exported" },
    ],
    options: {},
    advertiserCodes: [],
    customItemTypes: [],
    filters: {
      partnerId: null,
      advertiser: [],
      type: [],
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: null,
    },
  }),
  created() {
    this.fetchAdvertiserCodes();
    this.fetchCustomItemTypes();
    this.getGlobalFilterDates();
  },
  watch: {
    $route: "fetchCustomItems",
    options: {
      handler() {
        this.fetchCustomItems({ resetPagination: false });
      },
      deep: true,
    },
  },
  methods: {
    fetchCustomItems({ resetPagination }) {
      this.setGlobalFilterDates();

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loading = true;

      this.axios
        .get(`/custom_item/`, {
          params: {
            page_num: resetPagination ? 1 : page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: sortDesc[0],
            filter: writeFilterAttr(this.filters),
          },
        })
        .then((r) => {
          this.customItems = r.data.data;
          this.totalCustomItems = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchAdvertiserCodes() {
      this.loading = true;
      this.axios
        .get(`/sales_short_project_code/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.advertiserCodes = r.data.data.map(({ name, value }) => ({
            value: name,
            text: value,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    fetchCustomItemTypes() {
      this.loading = true;
      this.axios
        .get(`/custom_item_type/`)
        .then((r) => {
          // Map into v-select understandable object.
          this.customItemTypes = r.data.data.map((value) => ({
            value,
            text: value,
          }));
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.response.data.message || e.toString();
        })
        .then(() => (this.loading = false));
    },
    getGlobalFilterDates() {
      const { dateFrom, dateTo } = this.$store.state;

      this.filters.dateFrom = dateFrom;
      this.filters.dateTo = dateTo;
    },
    setGlobalFilterDates() {
      let { dateFrom, dateTo } = this.filters;

      if (dateFrom === "") {
        dateFrom = null;
      }

      if (dateTo === "") {
        dateTo = null;
      }

      this.$store.commit("setFilterDates", { dateFrom, dateTo });
    },
  },
};
</script>

<style scoped>
.router-button {
  text-decoration: none;
}
</style>
