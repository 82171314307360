<template>
  <v-container>
    <v-alert type="info" v-if="loading">Retrieving Sales&hellip;</v-alert>

    <v-alert type="error" v-if="errored">{{ message }}</v-alert>

    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.externalSaleReference"
          outlined
          label="External Sale Reference"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filters.saleReference"
          outlined
          label="Sale Reference"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.externalConfirmationId"
          outlined
          label="External Confirmation ID"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="filters.exportStatus"
          :items="exportStatuses"
          label="Export Status"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="filters.dateFrom"
          outlined
          label="From YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="filters.dateTo"
          outlined
          label="To YYYY-MM-DD (incl.)"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-btn
          x-large
          block
          color="primary"
          @click="fetchSales({ resetPagination: true })"
          >Filters Toepassen</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="sales"
      :items-per-page="15"
      :options.sync="options"
      :server-items-length="totalSales"
      :loading="loading"
    >
      <template v-slot:[`item.sale_reference`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <router-link
                :to="{ name: 'sale.show', params: { saleId: item.id } }"
                >{{ item.sale_reference }}</router-link
              >
            </span>
          </template>
          <span>{{ item.external_sale_reference }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.matched`]="{ item }">
        <v-simple-checkbox v-model="item.matched" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.has_corrections`]="{ item }">
        <v-simple-checkbox
          v-model="item.has_corrections"
          disabled
        ></v-simple-checkbox>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
import * as ExportStatus from "../../misc/ExportStatus.js";

const writeFilterAttr = ({
  exportStatus,
  externalSaleReference,
  saleReference,
  externalConfirmationId,
  dateFrom,
  dateTo,
}) =>
  [
    ExportStatus.filter("SaleExportStatusModel", exportStatus),
    externalSaleReference && {
      field: "external_sale_reference",
      operator: "$contains",
      value: externalSaleReference,
    },
    saleReference && {
      field: "sale_reference",
      operator: "$contains",
      value: saleReference,
    },
    externalConfirmationId && {
      field: "external_confirmation_id",
      operator: "$contains",
      value: externalConfirmationId,
    },
    dateFrom && {
      field: "sale_closed_date",
      operator: "$ge",
      value: moment(dateFrom).format("YYYY-MM-DD"),
    },
    dateTo && {
      field: "sale_closed_date",
      operator: "$lt",
      value: moment(dateTo).add(1, "day").format("YYYY-MM-DD"),
    },
  ].filter((v) => v);

export default {
  props: ["hasAccess", "hasAccessFull", "hasAccessRestricted"],
  data: () => ({
    loading: false,
    errored: false,
    message: null,
    sales: [],
    totalSales: 0,
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Datum (Sale Closed)", value: "sale_closed_date" },
      { text: "Partner ID", value: "partner_id" },
      { text: "Sale Reference", value: "sale_reference" },
      { text: "External Confirmation ID", value: "external_confirmation_id" },
      { text: "Final Status", value: "status" },
      { text: "Amount (org)", value: "fee_org" },
      { text: "Amount (partner)", value: "fee_partner" },
      { text: "Import Match", value: "matched" },
      { text: "Has Correction(s)", value: "has_corrections" },
    ],
    options: {},
    exportStatuses: [
      { value: ExportStatus.EXPORTED, text: "Geëxporteerd" },
      { value: ExportStatus.UNEXPORTED, text: "Niet geëxporteerd" },
      { value: ExportStatus.INDETERMINATE, text: "Ongeacht geëxporteerd" },
    ],
    filters: {
      exportStatus: ExportStatus.UNEXPORTED,
      externalSaleReference: null,
      saleReference: null,
      externalConfirmationId: null,
      dateFrom: moment().subtract(1, "month").format("YYYY-MM-DD"),
      dateTo: null,
    },
  }),
  created() {
    this.getGlobalFilterDates();
  },
  watch: {
    $route: "fetchSales",
    options: {
      handler() {
        this.fetchSales({ resetPagination: false });
      },
      deep: true,
    },
  },
  methods: {
    fetchSales({ resetPagination }) {
      this.setGlobalFilterDates();

      const { page, itemsPerPage, sortBy, sortDesc } = this.options;

      this.loading = true;
      this.axios
        .get(`/sale/`, {
          params: {
            page_num: resetPagination ? 1 : page,
            page_size: itemsPerPage,
            order_by_key: sortBy[0],
            order_desc: sortDesc[0],
            filter: writeFilterAttr(this.filters),
          },
        })
        .then((r) => {
          this.sales = r.data.data;
          this.totalSales = r.data._meta.pagination.total;
        })
        .catch((e) => {
          this.errored = true;
          this.message = e.toString();
        })
        .then(() => (this.loading = false));
    },
    getGlobalFilterDates() {
      const { dateFrom, dateTo } = this.$store.state;

      this.filters.dateFrom = dateFrom;
      this.filters.dateTo = dateTo;
    },
    setGlobalFilterDates() {
      let { dateFrom, dateTo } = this.filters;

      if (dateFrom === "") {
        dateFrom = null;
      }

      if (dateTo === "") {
        dateTo = null;
      }

      this.$store.commit("setFilterDates", { dateFrom, dateTo });
    },
  },
};
</script>
